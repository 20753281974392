@font-face {
    font-family: "iconfont"; /* Project id 3790954 */
    src: url('//at.alicdn.com/t/c/font_3790954_r8q3fazzw3o.woff2?t=1670144153295') format('woff2'),
    url('//at.alicdn.com/t/c/font_3790954_r8q3fazzw3o.woff?t=1670144153295') format('woff'),
    url('//at.alicdn.com/t/c/font_3790954_r8q3fazzw3o.ttf?t=1670144153295') format('truetype');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-xiangyoujiantou4:before {
    content: "\e761";
}

.icon-baocunbingshangchuangeiyonghu:before {
    content: "\e742";
}

.icon-bianji:before {
    content: "\e743";
}

.icon-biaogexuankuang1:before {
    content: "\e744";
}

.icon-baogaoshenhe:before {
    content: "\e745";
}

.icon-biaogexuankuang2:before {
    content: "\e746";
}

.icon-danchuangguanbianniu:before {
    content: "\e747";
}

.icon-chaxun:before {
    content: "\e748";
}

.icon-jizhumima:before {
    content: "\e749";
}

.icon-jizhumima2:before {
    content: "\e74a";
}

.icon-jiance:before {
    content: "\e74b";
}

.icon-jiancejieguoxuanze1:before {
    content: "\e74c";
}

.icon-jiancedengji:before {
    content: "\e74d";
}

.icon-jiantou2:before {
    content: "\e74e";
}

.icon-jiancedengji-xuanzhong:before {
    content: "\e74f";
}

.icon-jiancejieguoxuanze2:before {
    content: "\e750";
}

.icon-paixu:before {
    content: "\e751";
}

.icon-jiantou3:before {
    content: "\e752";
}

.icon-sousuokuangjiantou:before {
    content: "\e753";
}

.icon-shangchuantupian:before {
    content: "\e754";
}

.icon-mima:before {
    content: "\e755";
}

.icon-tianjia:before {
    content: "\e756";
}

.icon-shanchu:before {
    content: "\e757";
}

.icon-tuichuzhanghao:before {
    content: "\e758";
}

.icon-zhanghao:before {
    content: "\e759";
}

.icon-xuanzejianceshijian:before {
    content: "\e75a";
}

.icon-renyuanguanli:before {
    content: "\e75b";
}

.icon-renyuanguanli-xuanzhong:before {
    content: "\e75c";
}

.icon-yuyuedidianguanli:before {
    content: "\e75d";
}

.icon-yuyuedidianguanli-xuanzhong:before {
    content: "\e75e";
}

.icon-baogaoshenhe-xuanzhong:before {
    content: "\e75f";
}

.icon-zhongzhi:before {
    content: "\e760";
}
